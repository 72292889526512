import { FC } from "react";

import { modifyExternalLinks } from "./helpers.ts";

export const HtmlContent: FC<{ html: string }> = ({ html }) => {
  const modifiedHtml = modifyExternalLinks(html);

  return (
    <div className="html-content" dangerouslySetInnerHTML={{ __html: modifiedHtml }} />
  );
};
