import { Tokens } from "@sbetdev2/sbet-utils/constants";

export enum ValidationTypes {
  WITHDRAW = "withdraw",
  TRANSFER = "transfer",
}

export type BalanceType = Record<Tokens, number>;

export type BonusTokens = Extract<
  keyof typeof Tokens,
  "SEOS" | "SUSD" | "SSOL" | "SUSDC" | "SBTC" | "SLTC" | "SETH"
>;

export type RatesType = Record<Tokens, number>;

export type NetworkDataType = {
  name: string;
  value: string;
  token: Tokens;
  ptoken: Tokens;
  isPNetwork?: boolean;
};

export type ValidateWithdrawPropsType = {
  value: number;
  balances: BalanceType;
  type: ValidationTypes;
  rates?: RatesType;
  currency: Tokens;
  fee?: number | null;
};
