import { useSelector } from "react-redux";

import { currencies, fiatCurrenciesAvailable } from "constants/currencies.ts";

import { formatAmount, isMBtc } from "utils/currencyUtils";
import { tokenIcons } from "utils/iconUtils.ts";

import { getViewCurrency } from "../../selectors/uiSelectors";
import { getExchangeRates } from "../../selectors/walletSelectors";

const SumView = ({
  fiatCaption = true,
  tokenCaption = true,
  fiatCurrency,
  sum,
  token,
  balanceView = false,
  showIcon = true,
  iconSize = 16,
  fullPrecision = false,
}) => {
  const hasIcon = token in currencies;
  const tokenName = currencies[token] ? currencies[token].displayName : token;
  return (
    <div style={{ display: "inline-flex", alignItems: "center" }}>
      <span>{formatAmount(sum, fiatCurrency, fiatCaption, fullPrecision)}</span>
      {hasIcon && showIcon ? (
        <span style={{ display: "inline-flex", alignItems: "center" }}>
          <img
            src={tokenIcons[token]}
            alt={`icon of ${tokenName}`}
            style={{ width: iconSize, height: iconSize, margin: "0 4px 0 4px" }}
          />
        </span>
      ) : null}
      {tokenCaption && (!fiatCaption || balanceView) ? (
        <span style={{ margin: "0 auto 0 0" }}>{isMBtc(token) ? "mBTC" : tokenName}</span>
      ) : null}
    </div>
  );
};

const BalanceView = ({
  fiatCaption = true,
  tokenCaption = true,
  fiatCurrency,
  sum,
  token,
  showIcon = true,
  iconSize = 16,
}) => {
  const tokenName = currencies[token] ? currencies[token].displayName : token;

  return (
    <div style={{ display: "inline-flex", alignItems: "center" }}>
      <span className={"balance-group"}>
        <span>{formatAmount(sum, fiatCurrency, fiatCaption)}</span>
        {showIcon ? (
          <img
            src={tokenIcons[fiatCurrency === token ? fiatCurrency : token]}
            alt={fiatCurrency === token ? fiatCurrency : tokenName}
            style={{ width: iconSize, height: iconSize, margin: "0 4px 0 4px" }}
          />
        ) : null}
      </span>
      {tokenCaption ? (
        <span className="token-caption">{isMBtc(token) ? "mBTC" : tokenName}</span>
      ) : null}
    </div>
  );
};

export const Sum = ({
  value,
  token,
  fiatCaption = true,
  tokenCaption = true,
  balanceView = false,
  SumComponent = SumView,
  showIcon = true,
  iconSize = 16,
  convert = true,
  showCrypto = false,
}) => {
  const rates = useSelector(getExchangeRates);
  const fiatCurrency = useSelector(getViewCurrency);

  const fiatView = !showCrypto && !!+localStorage.getItem("fiat_view");

  if (
    fiatView &&
    rates &&
    rates.crypto &&
    rates.fiat &&
    fiatCurrency &&
    fiatCurrenciesAvailable.includes(fiatCurrency) &&
    fiatCurrency !== token
  ) {
    let sum = +(value + "").replace(/[^\d.]/, "");
    if (convert) {
      sum *= rates.crypto[token]; // get EOS
      sum /= rates.crypto.USDT; // get USDT
      sum *= rates.fiat[fiatCurrency];
    }

    return (
      <SumComponent
        fiatCaption={fiatCaption}
        tokenCaption={tokenCaption}
        fiatCurrency={fiatCurrency}
        sum={sum}
        token={token}
        balanceView={balanceView}
        showIcon={showIcon}
        iconSize={iconSize}
      />
    );
  }

  const isCrypto = token in currencies;

  return (
    <SumComponent
      fiatCaption={!isCrypto}
      tokenCaption={tokenCaption}
      token={token}
      fiatCurrency={token}
      sum={value}
      balanceView={balanceView}
      showIcon={showIcon}
      iconSize={iconSize}
    />
  );
};

export const BalanceSum = (props) => <Sum {...props} SumComponent={BalanceView} />;

export const WalletSum = (props) => (
  <Sum {...props} SumComponent={(props) => <SumView {...props} fullPrecision={true} />} />
);

export default Sum;
