import { Fiats, Tokens } from "@sbetdev2/sbet-utils/constants";

import { currencies, fiatCurrenciesAvailable } from "constants/currencies.ts";

export const isMBtc = (token) => [Tokens.BTC, Tokens.SBTC].includes(token);

const convertToFeeToken = (token) => {
  switch (token) {
    case Tokens.LTC:
    case Tokens.SLTC:
      return Tokens.LTC;
    case Tokens.BTC:
    case Tokens.SBTC:
      return Tokens.BTC;
    case Tokens.ETH:
    case Tokens.SETH:
      return Tokens.ETH;
    case Tokens.USDT:
    case Tokens.SUSD:
    case Tokens.TRX_USDT:
      return Tokens.USDT;
    case Tokens.EOS:
    case Tokens.SEOS:
      return Tokens.EOS;
    case Tokens.SOL:
    case Tokens.SSOL:
      return Tokens.SOL;
    case Tokens.USDC:
    case Tokens.SUSDC:
      return Tokens.USDC;
    case Tokens.DOGE:
    case Tokens.SDOGE:
      return Tokens.DOGE;
    case Tokens.BCH:
    case Tokens.SBCH:
      return Tokens.BCH;
    default:
      return "Unknown token";
  }
};

export const convertToChainToken = (token, returnMFormat = false) => {
  switch (token) {
    case Tokens.LTC:
    case Tokens.PLTC:
    case Tokens.SLTC:
      return Tokens.LTC;
    case Tokens.BTC:
    case "PBTC":
    case Tokens.SBTC:
    case "mBTC":
      return returnMFormat ? "mBTC" : Tokens.BTC;
    case Tokens.ETH:
    case Tokens.PETH:
    case Tokens.SETH:
      return Tokens.ETH;
    case Tokens.USDT:
    case Tokens.SUSD:
    case Tokens.TRX_USDT:
    case Fiats.USD:
      return Tokens.USDT;
    case Tokens.EOS:
    case Tokens.SEOS:
      return Tokens.EOS;
    case Tokens.SOL:
    case Tokens.SSOL:
      return Tokens.SOL;
    case Tokens.USDC:
    case Tokens.SUSDC:
      return Tokens.USDC;
    case Tokens.DOGE:
    case Tokens.SDOGE:
      return Tokens.DOGE;
    case Tokens.BCH:
    case Tokens.SBCH:
      return Tokens.BCH;
    default:
      return "Unknown token";
  }
};

export function getPossibleAccounts() {
  return Object.values(currencies).map((currency) => currency.code);
}

const getCurrency = (token) =>
  currencies[token] || { ...currencies.EOS, name: token, displayName: token };

export const getPrimaryLeagueOrderThreshold = (live) => (live ? 1000 : 500);

export const getCurrencyDataByToken = (token) =>
  Object.values(currencies).find((currency) => currency.code === token);

export function formatAmount(amount, token, caption = true, fullPrecision = false) {
  const displayName = getTokenDisplayName(token);
  const isFiat = fiatCurrenciesAvailable.includes(token);
  const displayAmount = isFiat
    ? amount !== "" && !Number.isNaN(+amount)
      ? (+amount).toFixed(2)
      : amount
    : formatTokenAmount(amount, token, false, fullPrecision);
  if (Number.isNaN(displayAmount))
    return `0.${isFiat ? "00" : isMBtc(token) ? "00000" : "0000"} ${displayName}`;
  return caption ? `${displayAmount} ${displayName}` : displayAmount;
}
export const trimTrailingZeros = (amount) => {
  if (amount.indexOf(".") !== -1 || amount.indexOf(",") !== -1) {
    return amount.replace(/([.,])?0+$/, "");
  }
  return amount;
};

export const tokenToFiat = (amount, token, currency, rates) => {
  if (
    !rates ||
    !rates.crypto ||
    !rates.fiat ||
    !rates.crypto[token] ||
    !rates.crypto.USDT ||
    !rates.fiat[currency]
  ) {
    return amount;
  }
  let result = amount;
  result *= rates.crypto[token]; // to EOS
  result /= rates.crypto.USDT; // to USDT
  result *= rates.fiat[currency];
  if (isMBtc(token)) result /= 1000;
  return result;
};

export const fiatToToken = (amount, token, currency, rates, isCasinoGame) => {
  if (
    !rates ||
    !rates.crypto ||
    !rates.fiat ||
    !rates.crypto[token] ||
    !rates.crypto.USDT ||
    !rates.fiat[currency]
  ) {
    return amount;
  }
  let result = amount;
  result /= rates.fiat[currency]; // to USD
  result *= rates.crypto.USDT; // to EOS
  result /= rates.crypto[token]; // to token
  if (isMBtc(token)) result *= 1000;
  if (isCasinoGame) {
    return result;
  }
  return Math.trunc(result * 10000) / 10000;
};

export function formatTokenAmount(
  amount,
  token,
  useShortPrecision = false,
  walletPrecision = false
) {
  const currency = getCurrency(token);
  const dAmount = currency.toDisplayValue(amount);
  const {
    fullDisplayPrecision,
    shortDisplayPrecision,
    walletDisplayPrecision,
    precision,
  } = currency;
  const displayPrecision = walletPrecision
    ? walletDisplayPrecision || fullDisplayPrecision
    : useShortPrecision
    ? shortDisplayPrecision
    : fullDisplayPrecision;
  const minValue = 5 * Math.pow(10, -precision - 1);

  return Math.abs(dAmount) > minValue
    ? (Math.trunc(dAmount * 10 ** displayPrecision) / 10 ** displayPrecision)
        .toFixed(displayPrecision)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : Number(0).toFixed(displayPrecision);
}

export function getTokenDisplayName(token) {
  return getCurrency(token).displayName;
}

export const formatFiatAmount = (x, currency, locale = "en-US") => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  return formatter.format(x);
};

export const convertToFiat = ({ amount, fiat, crypto, rates, precision = 0 }) => {
  const feeCrypto = convertToFeeToken(crypto);
  if (
    !amount ||
    !rates ||
    !rates.crypto ||
    !rates.crypto[feeCrypto] ||
    !rates.fiat ||
    !rates.fiat[fiat] ||
    !rates.crypto[Tokens.USDT]
  ) {
    return 0;
  }
  const calculatedAmount =
    (amount * rates.crypto[feeCrypto] * rates.fiat[fiat]) / rates.crypto[Tokens.USDT];

  return calculatedAmount / Math.pow(10, precision);
};

export const currencyAmountAvailable = ({
  balances,
  currencies,
  selectedCurrency = "",
}) => {
  if (
    !balances &&
    typeof balances !== "object" &&
    !currencies &&
    !Array.isArray(currencies)
  ) {
    return [];
  }
  const selectedCurrencies = [...currencies];
  const availableCurrencies = Object.entries(balances).reduce((acc, curr) => {
    const [currency, amount] = curr;
    if (amount && currency) {
      acc.push(currency);
    }
    return acc;
  }, []);
  if (availableCurrencies.includes(selectedCurrency)) {
    selectedCurrencies.unshift(selectedCurrency);
  }
  return selectedCurrencies.filter((currency) => availableCurrencies.includes(currency));
};

// TODO: Remove after completed swap for all users
export const getBaseToken = (token) => {
  switch (token) {
    case "PBTC":
      return Tokens.BTC;
    case "PLTC":
      return Tokens.LTC;
    case "PETH":
      return Tokens.ETH;
    default:
      return token;
  }
};
