import { useMemo } from "react";
import { useLocation } from "react-router";

import PromoModal from "containers/Promo/PromoModal";
import AccountCreated from "containers/popups/AccountCreated/AccountCreated";
import ActivateEmail from "containers/popups/ActivateEmail/ActivateEmail";
import ActivationPopup from "containers/popups/ActivationPopup";
import BettingIntegrity from "containers/popups/BettingIntegrity/index.ts";
import BonusHowto from "containers/popups/BonusHowto/index.ts";
import BonusModal from "containers/popups/BonusModal/index.ts";
import CurrencyPopup from "containers/popups/CurrencyPopup/index.ts";
import Dividends from "containers/popups/Dividends/index.ts";
import ErrorPopupHandler from "containers/popups/ErrorPopupHandler/index.ts";
import LazyExtendExcludePopup from "containers/popups/ExtendExcludePopup/index.ts";
import Leaderboard from "containers/popups/Leaderboard/index.ts";
import { LazySignInPopup, LazySignUpPopup } from "containers/popups/LoginPopup/index.js";
import NotificationsPopup from "containers/popups/NotificationsPopup/index.ts";
import PromoSignInPopup from "containers/popups/PromoSignInPopup/PromoSignInPopup";
import PromoSignInPopup2 from "containers/popups/PromoSignInPopup/PromoSignInPopup2";
import PromoSignUpPopup from "containers/popups/PromoSignInPopup/PromoSignUpPopup";
import ReactivateUserPopup from "containers/popups/ReactivateUserPopup/index.ts";
import RiskFreeTerms from "containers/popups/RiskFreeTerms/index.ts";
import SettingsPopup from "containers/popups/SettingsPopup/index.ts";
import { Wallet } from "containers/popups/Wallet/index.ts";
import WeChat from "containers/popups/Wechat/index.ts";
import WelcomePopup from "containers/popups/WelcomePopup/index.ts";

import HashEventHandler from "components/event/HashEventHandler.tsx";
import DepositFunnel from "components/funnels/index.ts";

import { isBrowser } from "utils/browserUtils";

const HashRoutes = () => {
  const { hash } = useLocation();

  const route = useMemo(() => {
    const [routeHash, queryParams] = hash.split("?");
    return { routeHash, queryParams };
  }, [hash]);

  if (!isBrowser()) return null;

  switch (route.routeHash) {
    case "#dividends":
      return <Dividends />;
    case "#betting-integrity":
      return <BettingIntegrity />;
    case "#leaderboard":
      return <Leaderboard />;
    case "#currency":
      return <CurrencyPopup />;
    case "#wallet":
      return <Wallet />;
    case "#wallet/fiat":
      return <Wallet fiat />;
    case "#wallet/transfer":
      return <Wallet transfer />;
    case "#welcome":
      return <WelcomePopup />;
    case "#promo-sign-in":
      return <PromoSignInPopup />;
    case "#promo-sign-up":
      return <PromoSignUpPopup />;
    case "#promo-signin":
      return <PromoSignInPopup2 />;
    case "#sign-in":
      return <LazySignInPopup />;
    case "#signup":
      return <LazySignUpPopup queryParams={route.queryParams} />;
    case "#activate":
      return <ActivationPopup />;
    case "#settings":
    case "#settings/tfa":
    case "#settings/general":
      return <SettingsPopup />;
    case "#notifications":
    case "#notifications/all":
      return <NotificationsPopup />;
    case "#activate-email":
      return <ActivateEmail />;
    case "#account-created":
      return <AccountCreated />;
    case "#reactivate-account":
      return <ReactivateUserPopup queryParams={route.queryParams} />;
    case "#extend-exclude":
      return <LazyExtendExcludePopup queryParams={route.queryParams} />;
    case "#wechat":
      return <WeChat />;
    case "#bonus":
    case "#bonus/history":
      return <BonusModal />;
    case "#deposit-funnel":
      return <DepositFunnel />;
    case "#bonus-howto":
      return <BonusHowto />;
    case "#risk-free":
      return <PromoModal />;
    case "#risk-free-terms":
      return <RiskFreeTerms />;
    case "#event":
      return <HashEventHandler queryParams={route.queryParams} />;
    case "#error":
      return <ErrorPopupHandler queryParams={route.queryParams} />;
  }

  return null;
};

export default HashRoutes;
