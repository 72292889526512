import { isExternalLink } from "utils/urlUtils.ts";

export const modifyExternalLinks = (html: string) => {
  if (!html) return "";

  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      const href = link.getAttribute("href");

      if (isExternalLink(href)) {
        link.setAttribute("rel", "nofollow");
      }
    });

    return doc.body.innerHTML;
  } catch (e) {
    return html;
  }
};
