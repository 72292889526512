import { useLocation } from "react-router-dom";

import Accordion from "components/data/Accordion";

import { modifyExternalLinks } from "./helpers.ts";

export const Faq = ({ title = "", sections }) => {
  // FIXME: add param to block with anchor
  const { hash } = useLocation();
  const isEosFork = hash === "#eos-fork";

  return (
    <div className="faq-content">
      {title && <h2>{title}</h2>}
      {sections.map(({ q: question, a: answer }, i) => {
        const answerHtml = `<div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer"><div itemProp="text">${answer}</div></div>`;
        const modifiedHtmlAnswer = modifyExternalLinks(answerHtml);
        return (
          <div
            className="my-2"
            key={`faq-${i}`}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
          >
            <Accordion
              className="accordion-faq"
              header={
                <div className="card-header-title" itemProp="name">
                  {question}
                </div>
              }
              bodyClass="p-2"
              startOpen={true}
              scrollTo={!isEosFork || question.includes("reversed")}
            >
              <div dangerouslySetInnerHTML={{ __html: modifiedHtmlAnswer }} />
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};
