import i18n from "i18next";

import { eventType } from "constants/events";
import { sportUrl } from "constants/urls";

import whitelabelUtils from "utils/whitelabelUtils.ts";

export const getUrl = (url: string) => {
  if (i18n.language === whitelabelUtils?.defaultLanguage()) {
    return url;
  }
  return `/${i18n.language}${url}`;
};

export const getEventTypeUrl = (type: number) => {
  if (type === 1) return "sports";
  if (type === 2) return "live";
  return null;
};

export const getSportUrl = (sportId: string) => {
  return sportUrl[+sportId as keyof typeof sportUrl] || sportId;
};

export const getSlugWithoutId = (slug: string): string => {
  const lastHyphenIndex = slug.lastIndexOf("-");
  if (lastHyphenIndex !== -1 && /^\d+$/.test(slug.substring(lastHyphenIndex + 1))) {
    return slug.substring(0, lastHyphenIndex);
  }
  return slug;
};

export const getPreSportUrl = (sportId: string) => {
  return `/${getEventTypeUrl(eventType.prematch)}/${getSportUrl(sportId)}`;
};

export const getLiveSportUrl = (sportId: string) => {
  return `/${getEventTypeUrl(eventType.live)}/${getSportUrl(sportId)}`;
};

export const getLeagueUrl = (isLive: boolean, league: SbetLeague) => {
  if (!league.sport || !league.slug) {
    return `/${getEventTypeUrl(
      isLive ? eventType.live : eventType.prematch
    )}/${getSportUrl(league.sport)}/league/${league._id}`;
  }

  return `/${getEventTypeUrl(isLive ? eventType.live : eventType.prematch)}/${getSportUrl(
    league.sport
  )}/${getSlugWithoutId(league.slug)}`;
};

export const getEventUrl = (event: SbetEvent) => {
  if (!event.sport || !event.leagueSlug || !event.slug) {
    return `/${getEventTypeUrl(
      event.type === eventType.live ? eventType.live : eventType.prematch
    )}/${getSportUrl(event.sport)}/event/${event._id}`;
  }

  return `/${getEventTypeUrl(
    event.type === eventType.live ? eventType.live : eventType.prematch
  )}/${getSportUrl(event.sport)}/${getSlugWithoutId(event.leagueSlug)}/${event.slug}`;
};

export const getEosParkUrl = (trxId: string, token: string) => {
  switch (token) {
    case "BTC":
    case "PBTC":
    case "bitgoBTC":
      return `https://blockstream.info/tx/${trxId}`;
    case "LTC":
    case "PLTC":
    case "bitgoLTC":
      return `https://blockchair.com/litecoin/transaction/${trxId}`;
    default:
      return `https://eosauthority.com/transaction/${trxId}`;
  }
};

export const getSportIdBySlug = (slug: string) => {
  return (
    {
      soccer: 1,
      baseball: 2,
      basketball: 3,
      "boxing-mma": 5,
      esports: 7,
      hockey: 8,
      cricket: 9,
      football: 10,
      tennis: 11,
      afl: 12,
      "rugby-union": 13,
      "rugby-league": 14,
      volleyball: 15,
      handball: 16,
      "table-tennis": 17,
    }[slug] || ""
  );
};

export const slugify = (text: string) =>
  text
    .toString()
    .normalize("NFKD")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/_/g, "-")
    .replace(/--+/g, "-")
    .replace(/-$/g, "");

export const isExternalLink = (link?: string | null) => {
  if (typeof window !== "undefined" && link) {
    try {
      const url = new URL(link);
      return (
        !link.startsWith("/") &&
        !link.startsWith("#") &&
        url.origin !== window.location.origin
      );
    } catch (e) {
      return false;
    }
  }
};
