import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { getIsMirrorPopUpAvailable } from "selectors/countrySelectors";

import { localStorageUtils } from "utils/browserUtils";

import styles from "./styles.module.scss";

const MirrorPopup = () => {
  const alreadyShown = localStorageUtils.getItem("mirrorPopupShown") === "2";

  const isMirrorPopUpAvailable = useSelector(getIsMirrorPopUpAvailable);

  const isNeedShow = useCallback(
    () => !alreadyShown && isMirrorPopUpAvailable,
    [alreadyShown, isMirrorPopUpAvailable]
  );

  const [isShown, setIsShown] = useState<boolean>(isNeedShow());

  useEffect(() => {
    if (isNeedShow()) {
      setIsShown(true);
    }
  }, [alreadyShown, isMirrorPopUpAvailable]);

  const onClose = () => {
    localStorageUtils.setItem("mirrorPopupShown", 2);
    setIsShown(false);
  };

  if (!isShown) return null;

  return (
    <Modal centered isOpen autoFocus={false} className={styles.wrapper} toggle={onClose}>
      <ModalHeader toggle={onClose}>New Domain for GB Players</ModalHeader>
      <ModalBody>
        <div className={styles.body}>
          <p>
            Due to legal considerations, we occasionally need to update the website's
            address (mirror) for players in Great Britain.
          </p>
          <p>
            The current mirror, sportbet-uk.one, will soon stop working. To ensure
            uninterrupted access to our platform, please use our redirect domain:
          </p>
          <a
            className={styles.link}
            href="https://sportbet.network"
            target="_blank"
            rel="noopener noreferrer"
          >
            sportbet.network
          </a>
          <p>This link will always redirect you to the latest mirror for your region</p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MirrorPopup;
