import { supportedProviders } from "@sbetdev2/dbmodel/enums";
import { Tokens } from "@sbetdev2/sbet-utils/constants";

export const depositDescription = {
  [Tokens.BTC]: [
    { text: "Coins will be deposited after 1 network confirmation", type: "primary" },
    {
      text: "Deposit delay can be up to 5 minutes after network confirmation",
      type: "primary",
    },
    {
      text: "Min deposit {{amount}}",
      tParams: { amount: "0.00005 BTC" },
      type: "primary",
    },
  ],
  [Tokens.BCH]: [
    { text: "Coins will be deposited after 6 network confirmations", type: "primary" },
  ],
  [Tokens.DOGE]: [
    { text: "Coins will be deposited after 12 network confirmations", type: "primary" },
  ],
  [Tokens.LTC]: [
    { text: "Coins will be deposited after 2 network confirmations", type: "primary" },
    {
      text: "Deposit delay can be up to 5 minutes after network confirmation",
      type: "primary",
    },
    {
      text: "Min deposit {{amount}}",
      tParams: { amount: "0.0001 LTC" },
      type: "primary",
    },
  ],
  [Tokens.USDT]: [
    { text: "Coins will be deposited after 6 network confirmations", type: "primary" },
  ],
  [Tokens.ETH]: [
    { text: "Coins will be deposited after 6 network confirmations", type: "primary" },
  ],
  [Tokens.TRX_USDT]: [
    { text: "Coins will be deposited after 20 network confirmations", type: "primary" },
  ],
  [Tokens.EOS]: [
    { text: "Coins will be deposited after 1 network confirmation.", type: "primary" },
  ],
  [Tokens.SOL]: [
    { text: "Coins will be deposited after 50 network confirmations", type: "primary" },
  ],
  [Tokens.USDC]: [
    { text: "Coins will be deposited after 6 network confirmations", type: "primary" },
  ],
};

export const tokenNetworks = {
  [Tokens.EOS]: [
    {
      name: "EOS",
      value: "EOS",
      token: Tokens.EOS,
      ptoken: Tokens.EOS,
      isPNetwork: true,
    },
  ],
  [Tokens.SBET]: [
    {
      name: "EOS",
      value: "EOS",
      token: Tokens.EOS,
      ptoken: Tokens.SBET,
      isPNetwork: true,
    },
  ],
  [Tokens.BCH]: [
    { name: "Bitcoin Cash", value: "BCH", token: Tokens.BCH, ptoken: Tokens.BCH },
  ],
  [Tokens.DOGE]: [
    { name: "Dogecoin", value: "DOGE", token: Tokens.DOGE, ptoken: Tokens.DOGE },
  ],
  [Tokens.USDT]: [
    { name: "Ethereum (ERC20)", value: "ETH", token: Tokens.USDT, ptoken: Tokens.USDT },
    { name: "Tron (TRC20)", value: "TRX", token: Tokens.TRX_USDT, ptoken: Tokens.USDT },
    {
      name: "EOS",
      value: "EOS",
      token: Tokens.EOS,
      ptoken: Tokens.USDT,
      isPNetwork: true,
    },
  ],
  [Tokens.BTC]: [
    { name: "Bitcoin", value: "BTC", token: Tokens.BTC, ptoken: Tokens.BTC },
  ],
  [Tokens.LTC]: [
    { name: "Litecoin", value: "LTC", token: Tokens.LTC, ptoken: Tokens.LTC },
  ],
  [Tokens.ETH]: [
    { name: "Ethereum", value: "ETH", token: Tokens.ETH, ptoken: Tokens.ETH },
  ],
  [Tokens.SOL]: [{ name: "Solana", value: "SOL", token: Tokens.SOL, ptoken: Tokens.SOL }],
  [Tokens.USDC]: [
    { name: "Ethereum (ERC20)", value: "ETH", token: Tokens.USDC, ptoken: Tokens.USDC },
  ],
};

export const depositTokenNetworks = {
  ...tokenNetworks,
  [Tokens.USDT]: [
    { name: "Ethereum (ERC20)", value: "ETH", token: Tokens.USDT, ptoken: Tokens.USDT },
    { name: "Tron (TRC20)", value: "TRX", token: Tokens.TRX_USDT, ptoken: Tokens.USDT },
  ],
};

export const bonusCurrencies = {
  [Tokens.SEOS]: {
    name: Tokens.SEOS,
    realName: "EOS",
    code: "sbetbonuscur",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "EOS",
    bonusName: "EOS (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SBTC]: {
    name: Tokens.SBTC,
    realName: "BTC",
    code: "sbetbonuscur",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "mBTC",
    bonusName: "BTC (Bonus)",
    fromDisplayValue: (x: number) => x && x / 1000,
    toDisplayValue: (x: number) => x && x * 1000,
  },
  [Tokens.SBCH]: {
    name: Tokens.SBCH,
    realName: "BCH",
    code: "sbetbonuscur",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "BCH",
    bonusName: "BCH (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SDOGE]: {
    name: Tokens.SDOGE,
    realName: "DOGE",
    code: "sbetbonuscur",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "DOGE",
    bonusName: "DOGE (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SLTC]: {
    name: Tokens.SLTC,
    realName: "LTC",
    code: "sbetbonuscur",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 8,
    displayName: "LTC",
    bonusName: "LTC (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SETH]: {
    name: Tokens.SETH,
    realName: "ETH",
    code: "sbetbonuscur",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "ETH",
    bonusName: "ETH (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SUSD]: {
    name: Tokens.SUSD,
    realName: "USDT",
    code: "sbetbonuscur",
    precision: 4,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "USDT",
    bonusName: "USDT (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SSOL]: {
    name: Tokens.SSOL,
    realName: "SOL",
    code: "sbetbonuscur",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "SOL",
    bonusName: "SOL (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SUSDC]: {
    name: Tokens.SUSDC,
    realName: "USDC",
    code: "sbetbonuscur",
    precision: 6,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 6,
    displayName: "USDC",
    bonusName: "USDC (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
};

const basicCurrencies = {
  [Tokens.EOS]: {
    name: Tokens.EOS,
    code: "eosio.token",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "EOS",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    walletName: "EOS",
    mainNetwork: "EOS",
  },
  [Tokens.SBET]: {
    name: Tokens.SBET,
    code: "sportbetsbet",
    precision: 4,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "SBET",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    walletName: "SBET",
    mainNetwork: "EOS",
  },
  [Tokens.BTC]: {
    name: Tokens.BTC,
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "mBTC",
    fromDisplayValue: (x: number) => x && x / 1000,
    toDisplayValue: (x: number) => x && x * 1000,
    provider: supportedProviders.bitgo,
    walletName: "BTC",
    mainNetwork: "Bitcoin",
  },
  [Tokens.SOL_USDT]: {
    name: Tokens.SOL_USDT,
    code: "sportbetcurr",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "SOL:USDT",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "SOL USDT",
    mainNetwork: "USDT (SOL)",
    feeName: "USDT",
  },
  [Tokens.BCH]: {
    name: Tokens.BCH,
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "BCH",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "BCH",
    mainNetwork: "Bitcoin Cash",
  },
  [Tokens.DOGE]: {
    name: Tokens.DOGE,
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "DOGE",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "DOGE",
    mainNetwork: "Dogecoin",
  },
  [Tokens.LTC]: {
    name: Tokens.LTC,
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 6,
    fullDisplayPrecision: 6,
    walletDisplayPrecision: 8,
    displayName: "LTC",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    mainNetwork: "Litecoin",
    pNetwork: "EOS (pLTC)",
    walletName: "LTC",
  },
  [Tokens.ETH]: {
    name: Tokens.ETH,
    code: "sportbetcurr",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "ETH",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    mainNetwork: "Ethereum",
    pNetwork: "EOS (pETH)",
    walletName: "ETH",
  },
  [Tokens.USDT]: {
    name: Tokens.USDT,
    code: "sportbetcurr",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "USDT",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "USDT",
    mainNetwork: "Tether",
    pNetwork: "EOS",
    feeName: "USDT",
  },
  [Tokens.SOL]: {
    name: Tokens.SOL,
    code: "sportbetcurr",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "SOL",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "SOL",
    mainNetwork: "Solana",
  },
  [Tokens.USDC]: {
    name: Tokens.USDC,
    code: "sportbetcurr",
    precision: 6,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 6,
    displayName: "USDC",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "USDC",
    mainNetwork: "USD Coin",
    feeName: "USDC",
  },
  [Tokens.TRX_USDT]: {
    name: Tokens.TRX_USDT,
    code: "sportbetcurr",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "TRX:USDT",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "TRX USDT",
    mainNetwork: "USDT (TRC20)",
    pNetwork: "EOS network (USDT-EOS)",
    feeName: "USDT",
  },
};

export const currencies = {
  ...basicCurrencies,
  ...bonusCurrencies,
};

export const bonusCurrenciesOrder = [
  Tokens.SBTC,
  Tokens.SUSD,
  Tokens.SETH,
  Tokens.SLTC,
  Tokens.SSOL,
  Tokens.SUSDC,
  Tokens.SBCH,
  Tokens.SDOGE,
  Tokens.SEOS,
];

export const currenciesOrderSocial = [
  Tokens.BTC,
  Tokens.USDT,
  Tokens.ETH,
  Tokens.LTC,
  Tokens.SOL,
  Tokens.USDC,
  Tokens.BCH,
  Tokens.DOGE,
  Tokens.EOS,
  Tokens.SBET,
];

export const fiatCurrencies = {
  USD: {
    title: "USD",
    currency: "USD",
    active: true,
    sign: "$",
  },
  GBP: {
    title: "GBP",
    currency: "GBP",
    active: true,
    sign: "£",
  },
  EUR: {
    title: "EUR",
    currency: "EUR",
    active: true,
    sign: "€",
  },
  CAD: {
    title: "CAD",
    currency: "CAD",
    active: true,
    sign: "C$",
  },
  AUD: {
    title: "AUD",
    currency: "AUD",
    active: true,
    sign: "A$",
  },
  CNY: {
    title: "CNY",
    currency: "CNY",
    active: true,
    sign: "¥",
  },
  KRW: {
    title: "KRW",
    currency: "KRW",
    active: true,
    sign: "₩",
  },
  JPY: {
    title: "JPY",
    currency: "JPY",
    active: true,
    sign: "¥",
  },
  TRY: {
    title: "TRY",
    currency: "TRY",
    active: true,
    sign: "₺",
  },
  ZAR: {
    title: "ZAR",
    currency: "ZAR",
    active: true,
    sign: "R",
  },
};
export const fiatCurrenciesAvailable: Array<string> = Object.values(
  fiatCurrencies
).reduce((arr: Array<string>, curr) => {
  if (curr.active) arr.push(curr.currency);
  return arr;
}, []);

export const getBonusToken = (token: Tokens) => {
  switch (token) {
    case Tokens.EOS:
      return Tokens.SEOS;
    case Tokens.BTC:
      return Tokens.SBTC;
    case Tokens.LTC:
      return Tokens.SLTC;
    case Tokens.ETH:
      return Tokens.SETH;
    case Tokens.USDT:
      return Tokens.SUSD;
    case Tokens.SOL:
      return Tokens.SSOL;
    case Tokens.BCH:
      return Tokens.SBCH;
    case Tokens.DOGE:
      return Tokens.SDOGE;
    case Tokens.USDC:
      return Tokens.SUSDC;
    default:
      return null;
  }
};

export const altCoins = [
  "ethereum",
  "tether",
  "eos",
  "litecoin",
  "solana",
  "usdc",
  "bitcoin-cash",
  "dogecoin",
];
