import classNames from "classnames";
import { useSetupZendesk } from "hooks/useSetupZendesk.ts";
import React from "react";
import { NavLink } from "react-router-dom";

import BrowserOnly from "components/common/BrowserOnly.tsx";
import FlagIcon from "components/common/FlagIcon";
import HashLink from "components/common/HashLink";

import { isExternalLink } from "utils/urlUtils.ts";

import { FooterLinkProps, LinkType } from "./types";

const FooterLink: React.FC<FooterLinkProps> = ({
  url,
  flag,
  title = "",
  iconPath = "",
  type = "",
  restrict,
}) => {
  const { openChat } = useSetupZendesk();

  const content = (
    <>
      {iconPath && <img src={iconPath} width={16} height={16} alt={`${title} icon`} />}
      <span className={classNames({ "ml-2": iconPath })}>
        {title}
        {!!flag && (
          <>
            &nbsp;&nbsp;
            <FlagIcon code={flag} />
          </>
        )}
      </span>
    </>
  );

  const link = () => {
    switch (type) {
      case LinkType.inner:
        return (
          <NavLink className="d-block contact-link" to={url ?? "/"}>
            {content}
          </NavLink>
        );
      case LinkType.external:
        return (
          <a
            href={url}
            onClick={
              restrict?.liveChat
                ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openChat();
                  }
                : undefined
            }
            rel={
              isExternalLink(url) ? "nofollow noopener noreferrer" : "noopener noreferrer"
            }
            target="_blank"
            className="d-block contact-link"
            tabIndex={restrict?.tabIndex ?? 0}
          >
            {content}
          </a>
        );
      case LinkType.hash:
        return <HashLink to={url}>{content}</HashLink>;
    }
  };

  switch (true) {
    case restrict?.registeredOnly:
      return null;
    case restrict?.browserOnly:
      return <BrowserOnly>{link()}</BrowserOnly>;
    default:
      return link();
  }
};

export default FooterLink;
