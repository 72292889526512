import { FC } from "react";

import { ReactComponent as BitcoinCash } from "img/token-icons/bitcoin-cash-icon.svg";
import { ReactComponent as BitcoinIcon } from "img/token-icons/bitcoin-icon.svg";
import { ReactComponent as DogecoinIcon } from "img/token-icons/dogecoin-icon.svg";
import { ReactComponent as EOSIcon } from "img/token-icons/eos-icon.svg";
import { ReactComponent as EthereumIcon } from "img/token-icons/ethereum-icon.svg";
import { ReactComponent as LitecoinIcon } from "img/token-icons/litecoin-icon.svg";
import { ReactComponent as SbetIcon } from "img/token-icons/sbet-icon.svg";
import { ReactComponent as SolanaIcon } from "img/token-icons/solana-icon.svg";
import { ReactComponent as TetherIcon } from "img/token-icons/tether-icon.svg";
import { ReactComponent as USDCIcon } from "img/token-icons/usdc-icon.svg";

export enum TokenNames {
  ETHEREUM = "ethereum",
  LITECOIN = "litecoin",
  BITCOIN = "bitcoin",
  TETHER = "tether",
  SOLANA = "solana",
  USDC = "usdc",
  SBET = "sbet",
  EOS = "eos",
  DOGE = "dogecoin",
  BCH = "bitcoin-cash",
}

export const TokenIcon: FC<{ iconName: TokenNames }> = ({ iconName }) => {
  const getIcon = (name: TokenNames) => {
    switch (name) {
      case TokenNames.ETHEREUM:
        return <EthereumIcon />;
      case TokenNames.LITECOIN:
        return <LitecoinIcon />;
      case TokenNames.BITCOIN:
        return <BitcoinIcon />;
      case TokenNames.TETHER:
        return <TetherIcon />;
      case TokenNames.SOLANA:
        return <SolanaIcon />;
      case TokenNames.USDC:
        return <USDCIcon />;
      case TokenNames.SBET:
        return <SbetIcon />;
      case TokenNames.EOS:
        return <EOSIcon />;
      case TokenNames.BCH:
        return <BitcoinCash />;
      case TokenNames.DOGE:
        return <DogecoinIcon />;
    }
  };
  return <div className="icon">{getIcon(iconName)}</div>;
};
